import PropTypes from 'prop-types';
import React from 'react';

export default function Select({
  textLabel,
  name,
  error,
  register,
  onChange,
  // defaultValue,
  selectClassName,
  selectOptions,
  id,
  disabled,
  withIndex,
  withDefaultOption,
  registerConfig,
}) {
  /**
   * className property is mostly to change label text color
   */

  /**
   * withIndex property is to know if we are going to render the
   * option with their map INDEX or their ID instead.
   *
   */

  /**
   * renderSelectOptions function is to render all the selected options we will need,
   * this receives an array, this can change in the future depending on the backend
   */

  let renderSelectOptions = () => {
    const options = selectOptions.map((option, index) => {
      // We add the selected attribute to the first one, !this can change
      if (withIndex) {
        return (
          <option value={index} key={index} id={option.id}>
            {option.name}
          </option>
        );
      } else {
        return (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        );
      }
    });

    if (withDefaultOption) {
      return [
        <option key={'default'} value={null}>
          {' '}
          Selecciona una opción
        </option>,
        ...options,
      ];
    }

    return options;
  };

  // LabelClasses and borderClasses
  let borderClasses = 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500';
  let labelClasses = 'text-gray-700';
  if (error) {
    labelClasses = 'text-pink-700';
    borderClasses = 'border-pink-300 focus:ring-pink-500 focus:border-pink-500 text-pink-500';
  }
  if (disabled) {
    labelClasses = 'text-gray-300';
    borderClasses = 'border-gray-300 text-gray-300';
  }

  return (
    <div>
      <label htmlFor="location" className={`block font-bold text-sm font-body ${labelClasses}`}>
        {textLabel}
      </label>
      <select
        disabled={disabled}
        id={id}
        name={name}
        // value={defaultValue}
        {...register(id, registerConfig)}
        onChange={onChange}
        className={`mt-1 block font-body w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${selectClassName} ${borderClasses}`}
      >
        {renderSelectOptions()}
      </select>
      {error && <p className="text-sm font-body font-medium text-red-500 pt-2">{error}</p>}
    </div>
  );
}

Select.propTypes = {
  textLabel: PropTypes.string,
  id: PropTypes.any,
  name: PropTypes.string,
  withIndex: PropTypes.bool,
  selectClassName: PropTypes.string,
  register: PropTypes.func,
  registerConfig: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  selectOptions: PropTypes.array,
  withDefaultOption: PropTypes.bool,
  defaultValue: PropTypes.number,
};

Select.defaultProps = {
  className: 'text-gray-700 text-sm',
  withIndex: true,
  withDefaultOption: false,
  defaultValue: 0,
};
