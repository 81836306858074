import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const ACCOMPANIMENT_REQUEST_PATH = 'accompaniment-requests/';
const PRIVATE_PATH = 'private/';

export const listAccompanimentRequests = async (token, filters, search) => {
  const { data } = await axios.get(`${API_URL}${PRIVATE_PATH}${ACCOMPANIMENT_REQUEST_PATH}`, {
    headers: headers(token),
    params: {
      status: filters.status,
      search: search,
    },
  });
  return data;
};
