import { Switch } from '@headlessui/react';
import BasicLayout from 'components/BasicLayout';
import CompleteProfile from 'components/CompleteProfile';
import Loader from 'components/Loader';
import moment from 'moment';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { retrievePatient, updatePatientState } from 'services/patient';
import { getToken } from 'state/auth';
import { selectedPatient as selectedPatientState } from 'state/patient';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Profile() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);
  const [selectedPatient] = useRecoilState(selectedPatientState);

  // LOCAL STATE
  const [isActive, setIsActive] = useState(false);
  // const [isPageLoading, setIsPageLoading] = useState(true);

  // REACT QUERY
  const { data, isSuccess, isLoading, error, refetch } = useQuery(
    ['retrievePatient', token, selectedPatient],
    () => retrievePatient(token, selectedPatient),
    {
      onSuccess: (data) => {
        setIsActive(data?.is_active);
      },
      refetchOnWindowFocus: false,
    },
  );

  const updatePatientStateMutation = useMutation(
    (data) => {
      updatePatientState(token, selectedPatient, data.is_active);
    },
    {
      onSuccess: () => {
        toast.success('Se guardo la información con exito.');
      },
      onError: () => {
        toast.error('Algo salio mal');
        refetch();
      },
    },
  );

  const activeHandler = (value) => {
    updatePatientStateMutation.mutate({ is_active: value });
    setIsActive(value);
  };

  let since = '';
  let name = '';
  let location;
  if ((isSuccess || !!data) && !error) {
    since = moment(data.created_at).local().format('MMMM [del] YYYY');
    name = `${data?.first_name} ${data?.last_name}`;
    location = data?.address
      ? `${data?.address.state.name}, ${data?.address.state.country.name}`
      : 'No se a completado';
  }
  return (
    <BasicLayout title="Perfil">
      <div className="flex flex-col lg:flex-row box-border">
        <div className="flex-1 mr-0 md:mr-4">
          <CompleteProfile
            completeName={name}
            email={data?.email}
            phone={data?.cell_phone}
            tag={isActive ? 'Activo' : 'Inactivo'}
            location={location}
            aboutMe={data?.biography}
            image={data?.photo_URL}
            sinceWhen={since}
            userRole={'Paciente'}
          />

          {isLoading ? (
            <div className="mt-4 w-full flex justify-center items-center">
              <Loader className="h-12 w-12 text-primary" />
            </div>
          ) : null}
        </div>
        <div className="flex-1 ml-0 md:ml-2 mt-4 md:mt-0">
          <div className="mt-4 md:mt-0 p-4 border rounded-2xl relative filter cursor-pointer shadow-md">
            <Switch.Group as="div" className="flex items-center justify-between">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  Estado del paciente
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  {isActive ? 'Activo' : 'Inactivo'}
                </Switch.Description>
              </span>
              <Switch
                disabled={updatePatientStateMutation.isLoading}
                checked={isActive}
                onChange={activeHandler}
                className={classNames(
                  isActive ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    isActive ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
}
