import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const TICKETS_PATH = 'tickets/';

export const list = async (token, status, type) => {
  const { data } = await axios.get(`${API_URL}${TICKETS_PATH}g/`, {
    headers: headers(token),
    params: {
      status,
      type,
    },
  });
  return data;
};

export const retrieveDetail = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${TICKETS_PATH}g/${id}/`, {
    headers: headers(token),
  });
  return data;
};

export const approveReschedule = async (token, id, body) => {
  const { data } = await axios.patch(`${API_URL}${TICKETS_PATH}c/reschedule/${id}/`, body, {
    headers: headers(token),
  });
  return data;
};

export const rejectReschedule = async (token, id) => {
  const { data } = await axios.patch(
    `${API_URL}${TICKETS_PATH}c/reschedule/${id}/reject/`,
    {},
    {
      headers: headers(token),
    },
  );
  return data;
};
