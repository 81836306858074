import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import Button from './Button';
import ProfilePicture from './ProfilePicture';

export default function ProfrilePictureInput({ id, setValue, disabled, photoURL, prevImgSrc }) {
  const [imgSrc, setImgSrc] = useState(prevImgSrc);

  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleRemove = () => {
    setImgSrc(null);
    setValue(null);
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setValue(fileUploaded);
    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = function () {
      setImgSrc(reader.result);
    };
  };

  return (
    <div>
      <label htmlFor="ProfilePicture" className="block text-sm font-medium text-gray-700">
        Foto
      </label>
      <div className="mt-1 flex items-center">
        <span className="inline-block h-16 w-16 rounded-full overflow-hidden bg-gray-100">
          <ProfilePicture className="h-16 w-16" imgSrc={imgSrc ? imgSrc : photoURL} />
        </span>
        <>
          <Button
            disabled={disabled}
            type="button"
            primary
            onClick={handleClick}
            className="ml-5 w-24 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {imgSrc ? 'Cambiar' : 'Cargar'}
          </Button>
          {imgSrc ? (
            <Button
              disabled={disabled}
              type="button"
              onClick={handleRemove}
              className="ml-5 w-24 bg-red-500 py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Eliminar
            </Button>
          ) : null}
          <input
            disabled={disabled}
            id={id}
            ref={hiddenFileInput}
            type="file"
            className="hidden"
            multiple={false}
            onChange={handleChange}
          />
        </>
      </div>
    </div>
  );
}

ProfrilePictureInput.propTypes = {
  id: PropTypes.any,
  prevImgSrc: PropTypes.string,
  setValue: PropTypes.any,
  disabled: PropTypes.bool,
  photoURL: PropTypes.any,
};
