import {
  CalendarIcon,
  EyeIcon,
  LocationMarkerIcon,
  PlusIcon,
  SearchIcon,
  UserIcon,
  UserRemoveIcon,
} from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import ContactCard from 'components/ContactCard';
import Input from 'components/Input';
import Loader from 'components/Loader';
import TabContainer from 'components/TabContainer';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { listPsychologists } from 'services/psychologists';
import { getToken } from 'state/auth';
import { isArrayEmpty } from 'utils/base';
import { SPANISH_MONTHS } from 'utils/constants';
import { PSYCHOLOGIST_DEGREES } from 'utils/psychologist';

const tabs = [
  {
    name: 'Activos',
    icon: UserIcon,
    filters: { isActive: true },
  },
  {
    name: 'Inactivos',
    icon: UserRemoveIcon,
    filters: { isActive: false },
  },
];

export default function AllPsychologists() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);

  // LOCAL STATES
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters] = useState(tabs[0].filters);
  const [search, setSearch] = useState('');

  // REACT QUERY
  const { data, isSuccess, isLoading, refetch } = useQuery(
    ['listPsychologists', token, filters, search],
    () => listPsychologists(token, filters, search),
    {
      enabled: false,
    },
  );

  // ROUTER
  const navigate = useNavigate();

  useEffect(() => {
    const selectedTab = tabs[`${activeTab}`];
    setFilters(selectedTab.filters);
  }, [activeTab]);

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  useEffect(() => {
    const timeOut = setTimeout(() => refetch(), 500);
    return () => clearTimeout(timeOut);
  }, [search, refetch]);

  const searchHandler = (event) => {
    setSearch(event.target.value);
  };

  // TODO: manage errors

  return (
    <BasicLayout
      title="Psicólogos"
      button={
        <Button
          primary={true}
          icon={<PlusIcon className="h-5 w-5 text-white mr-2" />}
          className="border max-w-48% text-white font-medium rounded-md text-sm"
          onClick={() => navigate('new')}
        >
          Agregar nuevo psicólogo
        </Button>
      }
    >
      <TabContainer tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>
        <Input
          id="search"
          type="text"
          onChange={searchHandler}
          register={() => null}
          registerConfig={{}}
          icon={<SearchIcon className="h-6 w-6 text-gray-400" />}
          leftIcon={true}
          placeholder="Search"
          disabled={false}
        />
      </div>

      <div>
        {isLoading ? (
          <div className="w-full flex justify-center items-center text-center font-body mt-10">
            <Loader /> Buscando...
          </div>
        ) : (
          isSuccess &&
          isArrayEmpty(data) && (
            <div className="w-full flex justify-center items-center text-center font-body mt-10">
              Ningún resultado
            </div>
          )
        )}
        {isSuccess && !isArrayEmpty(data) && (
          <div className="flex flex-col md:grid grid-cols-2 auto-rows-fr gap-x-3 box-border">
            {data.map((psychologist, index) => {
              const createdDate = moment(psychologist.created_at, 'DD-MM-YYYYTHH:mm:ssZ').toDate();
              const infoDescription = [
                {
                  children: (
                    <p className={`font-body m-0 text-gray-500 text-xs`}>
                      {psychologist.address?.state.name}, {psychologist.address?.state.country.name}
                    </p>
                  ),
                  icon: (
                    <LocationMarkerIcon className="ml-0 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 mr-2" />
                  ),
                },
                {
                  children: (
                    <p className={`font-body m-0 text-gray-500 text-xs`}>
                      Paciente desde <strong>{SPANISH_MONTHS[createdDate.getMonth()]}</strong>
                    </p>
                  ),
                  icon: <CalendarIcon className="ml-0 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 mr-2" />,
                },
              ];

              const degree = PSYCHOLOGIST_DEGREES[psychologist.degree];
              return (
                <div key={index} className="my-4 border rounded-lg shadow-md">
                  <ContactCard
                    title={psychologist.first_name + ' ' + psychologist.last_name}
                    buttonInfo={[
                      {
                        text: 'Ver Detalle',
                        icon: <EyeIcon className="ml-0 h-5 w-5 text-primary mr-2" />,
                        onClickEvent: () => navigate(`detail/${psychologist.id}`),
                      },
                    ]}
                    infoDescription={infoDescription}
                    tag={degree.degree}
                    tagColor={degree.color}
                    withDot={false}
                  >
                    <img
                      className="rounded-full h-8 w-8 lg:w-10 lg:h-10"
                      src={psychologist.photo_URL}
                      alt=""
                    />
                  </ContactCard>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </BasicLayout>
  );
}
