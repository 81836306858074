import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const PAYMENTS_PATH = 'payments';
const PAYMENT_TICKETS_PATH = 'tickets';

export const listPaymentTickets = async (token, filters, search) => {
  /**
   * status -> 1 no payment, 2 payed
   * search -> filters for pat && psy emails
   */
  const { data } = await axios.get(`${API_URL}${PAYMENTS_PATH}/${PAYMENT_TICKETS_PATH}/`, {
    headers: headers(token),
    params: {
      search: search,
      status: filters.status,
    },
  });

  return data;
};

export const exportPaymentTickets = async (token, body) => {
  const { data } = await axios.post(
    `${API_URL}${PAYMENTS_PATH}/${PAYMENT_TICKETS_PATH}/export/`,
    body,
    {
      headers: headers(token),
    },
  );

  return data;
};
