import {
  SESSION_CANCELED,
  SESSION_CREATED,
  SESSION_FINALIZED,
  SESSION_IN_PROGRESS,
  SESSION_PAYED,
  SESSION_SCHEDULED,
  SESSION_TIME_COMPLETED,
  SESSION_UNPAYED,
} from './statuses';

export const sessionStatuses = {
  [SESSION_CREATED]: {
    text: 'Creada',
    color: 'text-gray-500',
  },
  [SESSION_SCHEDULED]: {
    text: 'Agendada',
    color: 'text-prumary',
  },
  [SESSION_PAYED]: {
    text: 'Pagada',
    color: 'text-green-500',
  },
  [SESSION_UNPAYED]: {
    text: 'Sin pago',
    color: 'text-yellow-500',
  },
  [SESSION_CANCELED]: {
    text: 'Cancelada',
    color: 'text-red-500',
  },
  [SESSION_IN_PROGRESS]: {
    text: 'En progreso',
    color: 'text-primary',
  },
  [SESSION_TIME_COMPLETED]: {
    text: 'Tiempo completado',
    color: 'text-secondary',
  },
  [SESSION_FINALIZED]: {
    text: 'Finalizada',
    color: 'text-green-500',
  },
};
