import {
  CalendarIcon,
  CheckCircleIcon,
  CurrencyDollarIcon,
  DocumentDownloadIcon,
  InformationCircleIcon,
  MailIcon,
  SearchIcon,
  UserIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import ContactCard from 'components/ContactCard';
import Input from 'components/Input';
import Loader from 'components/Loader';
import TabContainer from 'components/TabContainer';
import Title from 'components/Title';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listPaymentTickets } from 'services/payment';
import { getToken } from 'state/auth';
import { selectedPatient as selectedPatientState } from 'state/patient';
import { isArrayEmpty } from 'utils/base';

const tabs = [
  {
    name: 'Pagados',
    icon: CheckCircleIcon,
    filters: { status: 2 },
  },
  {
    name: 'No Pagados',
    icon: XCircleIcon,
    filters: { status: 1 },
  },
];

export default function PaymentList() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);
  const [, setSelectedPatient] = useRecoilState(selectedPatientState);

  // LOCAL STATES
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters] = useState(tabs[0].filters);
  const [search, setSearch] = useState('');

  // REACT QUERY
  const { data, isLoading, isSuccess, refetch } = useQuery(
    ['listPaymentTickets', token, filters, search],
    () => listPaymentTickets(token, filters, search),
    {
      enabled: false,
    },
  );

  // RRD
  const navigate = useNavigate();

  useEffect(() => {
    const selectedTab = tabs[`${activeTab}`];
    setFilters(selectedTab.filters);
  }, [activeTab]);

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  useEffect(() => {
    const timeOut = setTimeout(() => refetch(), 500);
    return () => clearTimeout(timeOut);
  }, [search, refetch]);

  const searchHandler = (event) => {
    setSearch(event.target.value);
  };

  // TODO: manage errors
  return (
    <BasicLayout
      title="Pacientes"
      button={
        <Button
          primary={true}
          className="border max-w-48% text-white font-medium rounded-md text-sm"
          icon={<DocumentDownloadIcon className="h-5 w-5 text-white mr-2" />}
          onClick={() => navigate('export')}
        >
          Exportar ticket
        </Button>
      }
    >
      <Title className="flex items-center">
        <InformationCircleIcon className="h-8 w-8 text-secondary mr-2" /> Todas las fechas de esta
        página están en UTC.
      </Title>
      <TabContainer tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>
        {/* TODO: check RHF here! */}
        <Input
          id="search"
          type="text"
          onChange={searchHandler}
          register={() => null}
          value={search}
          registerConfig={{}}
          icon={<SearchIcon className="h-6 w-6 text-gray-400" />}
          leftIcon={true}
          placeholder={search}
          disabled={false}
        />
      </div>

      <div>
        {isLoading ? (
          <div className="w-full flex justify-center items-center text-center font-body mt-10">
            <Loader /> Buscando...
          </div>
        ) : null}

        {isSuccess && isArrayEmpty(data) ? (
          <div className="text-center font-body mt-10">No se encontraron resultados</div>
        ) : null}

        {isSuccess && !isArrayEmpty(data) && (
          <div className="flex flex-col md:grid grid-cols-2 auto-rows-fr gap-x-3 box-border">
            {data.map((ticket, index) => {
              const title = `#${ticket.id}`;
              const patient = ticket.patient;
              const psychologist = ticket.psychologist;

              const paymentDate = moment(ticket.payment_date)
                .utc()
                .format('DD [de] MMMM [del] YYYY');
              let paidOn = '-';
              if (ticket.paid_on) {
                paidOn = moment(ticket.paid_on).utc().format('DD [de] MMMM [del] YYYY');
              }
              const infoDescription = [
                {
                  children: (
                    <p className={`font-bold m-0 text-gray-500 text-xs`}>
                      <span className="font-body">Fecha de corte: </span>
                      {paymentDate}
                    </p>
                  ),
                  icon: <CalendarIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
                },
                {
                  children: (
                    <p className={`font-bold m-0 text-gray-500 text-xs`}>
                      <span className="font-body">Fecha de pago: </span>
                      {paidOn}
                    </p>
                  ),
                  icon: <CalendarIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
                },
                {
                  children: (
                    <p className={`font-body m-0 text-gray-500 text-xs`}>{ticket.total_amount}</p>
                  ),
                  icon: <CurrencyDollarIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
                },
                {
                  children: (
                    <p className={`font-bold m-0 text-gray-500 text-xs`}>
                      <span className="font-body">Paciente: </span>
                      {patient.first_name} {patient.last_name}
                      <br />
                      <span className="font-body">Email: </span>
                      {patient.email}
                    </p>
                  ),
                  icon: <UserIcon className="ml-0 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 mr-2" />,
                },
                {
                  children: (
                    <p className={`font-bold m-0 text-gray-500 text-xs`}>
                      <span className="font-body">Cather: </span>
                      {psychologist ? `${psychologist.first_name} ${psychologist.last_name}` : '-'}
                      <br />
                      <span className="font-body">Email: </span>
                      {psychologist ? `${psychologist.email}` : '-'}
                    </p>
                  ),
                  icon: <UserIcon className="ml-0 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 mr-2" />,
                },
              ];

              return (
                <div key={index} className="my-4 border rounded-lg shadow-md">
                  <ContactCard
                    title={title}
                    buttonInfo={[
                      {
                        text: 'Detalles',
                        icon: <MailIcon className="ml-0 h-5 w-5 text-primary mr-2" />,
                        onClickEvent: () => {
                          setSelectedPatient('ds');
                          navigate(`detail/`);
                        },
                      },
                    ]}
                    infoDescription={infoDescription}
                    // tag={STATUS_PATIENT[parseInt(patient.status)].tag}
                    // tagColor={STATUS_PATIENT[parseInt(patient.status)].tagColor}
                    // withDot={STATUS_PATIENT[parseInt(patient.status)].withDot}
                  >
                    {/* <img className="rounded-full h-10 w-10" src={patient.photo_URL} alt="" /> */}
                  </ContactCard>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </BasicLayout>
  );
}
