import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const ACCOMPANIMENTS_PATH = 'accompaniments/';
const PRIVATE_PATH = 'private/';

export const getPrivateAccompaniment = async (token, id) => {
  // id -> patient id
  const { data } = await axios.get(`${API_URL}${ACCOMPANIMENTS_PATH}${PRIVATE_PATH}${id}/detail`, {
    headers: headers(token),
  });

  return data;
};

export const listAccompaniments = async (token, filters, search) => {
  const { data } = await axios.get(`${API_URL}${PRIVATE_PATH}${ACCOMPANIMENTS_PATH}`, {
    headers: headers(token),
    params: {
      status: filters.status,
      search: search,
    },
  });
  return data;
};

export const listSessions = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${ACCOMPANIMENTS_PATH}${id}/sessions/`, {
    headers: headers(token),
  });
  return data;
};
