import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  AcademicCapIcon,
  BellIcon,
  CashIcon,
  ChevronLeftIcon,
  HomeIcon,
  KeyIcon,
  LogoutIcon,
  MenuAlt2Icon,
  UserGroupIcon,
  XIcon,
} from '@heroicons/react/outline';
import { ClipboardListIcon } from '@heroicons/react/solid';
import MindoveLogoWhite from 'assets/icons/logotipo_white_transparent.png';
import Button from 'components/Button';
import NavItem from 'components/NavItem';
import { Fragment, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { auth as authState, getUser } from 'state/auth';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function App() {
  const [, setAuth] = useRecoilState(authState);
  const user = useRecoilValue(getUser);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Constants
  const navigate = useNavigate();
  const navigation = [
    { name: 'Inicio', href: '/ic', icon: HomeIcon, isAdminScreen: false },
    { name: 'Psicólogos', href: 'psychologists', icon: AcademicCapIcon, isAdminScreen: false },
    { name: 'Pacientes', href: 'patients', icon: UserGroupIcon, isAdminScreen: false },
    { name: 'Hops', href: 'hops', icon: KeyIcon, isAdminScreen: true },
    { name: 'Pagos', href: 'payments', icon: CashIcon, isAdminScreen: false },
    {
      name: 'Solicitudes',
      href: 'accompaniment-requests',
      icon: ClipboardListIcon,
      isAdminScreen: false,
    },
    {
      name: 'Acompañamientos',
      href: 'accompaniments',
      icon: ClipboardListIcon,
      isAdminScreen: false,
    },
    {
      name: 'Tickets',
      href: 'tickets',
      icon: ClipboardListIcon,
      isAdminScreen: false,
    },
  ];
  const userNavigation = [{ name: 'Sign out', onClick: () => setAuth(null) }];
  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img alt="Mindove" src={MindoveLogoWhite} />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) => {
                    if (item.isAdminScreen && user?.role !== 'admin') {
                      return null;
                    }

                    return (
                      <NavItem
                        key={item.name}
                        name={item.name}
                        href={item.href}
                        classNames={classNames}
                        Icon={item.icon}
                        IconClassName={
                          'mr-4 flex-shrink-0 h-6 w-6 text-primary hover:text-white focus:text-white'
                        }
                        onClick={setSidebarOpen}
                      />
                    );
                  })}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow pt-5 bg-primary overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img alt="Mindove" src={MindoveLogoWhite} />
          </div>
          <div className="mt-5 flex-1 flex flex-col">
            <nav className="flex-1 px-2 pb-4 space-y-1">
              {navigation.map((item) => {
                if (item.isAdminScreen && user?.role !== 'admin') {
                  return null;
                }

                return (
                  <NavItem
                    key={item.name}
                    name={item.name}
                    href={item.href}
                    classNames={classNames}
                    Icon={item.icon}
                    IconClassName={
                      'mr-3 flex-shrink-0 h-6 w-6 text-white hover:text-white focus:text-white'
                    }
                  />
                );
              })}
            </nav>
            <div className="my-2 p-2">
              <Button
                className="border border-1 rounded-md border-white text-white gap-2"
                icon={<LogoutIcon className="h-6 w-6 text-white" />}
                onClick={() => setAuth()}
              >
                Cerrar sesión
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 flex-shrink-0 flex items-center h-16 bg-white shadow">
          <ChevronLeftIcon
            className="text-gray-400 cursor-pointer h-6 w-6 sm:ml-4 md:ml-6"
            onClick={() => navigate(-1)}
          />
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex"></div>
            <div className="ml-4 flex items-center md:ml-6">
              <button
                type="button"
                className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full" src={user?.photo_URL} alt="" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <button
                            onClick={item.onClick}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'w-full block px-4 py-2 text-sm text-gray-700',
                            )}
                          >
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main>
          <div className="py-6">
            <Outlet />
          </div>
        </main>
      </div>
      <Toaster
        position="top-right"
        toastOptions={{
          // Define default options
          className: '',
          duration: 3000,
          style: {
            background: '#eaeaea',
            color: '#111827',
          },
        }}
      />
    </div>
  );
}
