import PropTypes from 'prop-types';
import React from 'react';

export default function Button({
  children,
  className,
  boxShadow,
  icon,
  id,
  disabled,
  reverseIcon,
  textCentered,
  primary = false,
  type = 'button',
  onClick,
}) {
  let disabledClass = '';
  if (disabled) {
    disabledClass = 'cursor-not-allowed';
  }
  return (
    <button
      type={type}
      id={id}
      disabled={disabled}
      onClick={onClick}
      className={`flex items-center px-6 py-3 font-medium font-body focus:outline-none w-full text-center ${disabledClass} ${
        primary ? 'text-white bg-primary ' + className : className
      } ${boxShadow && 'shadow-sm hover:shadow-lg'} ${
        reverseIcon ? 'flex-row-reverse' : 'inline-flex'
      } ${textCentered && 'justify-center'}
      `}
    >
      {icon}
      {children}
    </button>
  );
}

Button.propTypes = {
  primary: PropTypes.bool,
  boxShadow: PropTypes.bool,
  id: PropTypes.any,
  disabled: PropTypes.bool,
  reverseIcon: PropTypes.bool,
  textCentered: PropTypes.bool,
  children: PropTypes.any,
  icon: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  primary: false,
  boxShadow: true,
  reverseIcon: false,
  textCentered: true,
  className: 'text-base',
  type: 'button',
  id: null,
  disabled: false,
  onClick: () => true,
};
