import PropTypes from 'prop-types';

export default function NextSession({ startDateUTC, endDateUTC, timezone }) {
  const format = 'DD[ de ]MMMM[ del ]YYYY [a las] H:mm';

  // const adminStartDate = startDateUTC.local().format(format);
  // const adminEndDate = endDateUTC.local().format(format);

  const timezonedStartDate = startDateUTC.tz(timezone).format(format);
  const timezonedEndDate = endDateUTC.tz(timezone).format(format);

  return (
    <div>
      <p className="font-bold font-header text-sm text-gray-500 mb-2">Horarios Siguiente sesión:</p>
      <div className="flex flex-col md:flex-row justiry-between">
        <div className="mb-2 mr-4">
          <div className="flex items-center">
            <p className="text-gray-400 mr-2">Inicia:</p>
            <p className="text-gray-600">{timezonedStartDate}</p>
          </div>
          <div className="flex items-center">
            <p className="text-gray-400 mr-2">Termina:</p>
            <p className="text-gray-600">{timezonedEndDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

NextSession.propTypes = {
  startDateUTC: PropTypes.any.isRequired,
  endDateUTC: PropTypes.any.isRequired,
  timezone: PropTypes.string.isRequired,
};

NextSession.defaultProps = {};
