import { CalendarIcon, InformationCircleIcon } from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
import ContactCard from 'components/ContactCard';
import Loader from 'components/Loader';
import Title from 'components/Title';
import moment from 'moment';
import React from 'react';
import { useQueries } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  getPrivateAccompaniment as getPrivateAccompanimentService,
  listSessions,
} from 'services/accompaniment';
import { selectedAccompaniment } from 'state/accompaniment';
import { getToken } from 'state/auth';
import { sessionStatuses } from 'utils/parsers';

import UserContent from './UserContent';

export default function AccompanimentDetail() {
  // Global State
  const token = useRecoilValue(getToken);
  const [accompanimentId] = useRecoilState(selectedAccompaniment);

  // const listSessionsQuery = useQuery(['listSessions', token, accompanimentId], ,
  // );

  const [retrieveAccompanimentQuery, listSessionsQuery] = useQueries([
    {
      queryKey: ['getPrivateAccompaniment', token, accompanimentId],
      queryFn: () => getPrivateAccompanimentService(token, accompanimentId),
    },
    {
      queryKey: ['listSessions', token, accompanimentId],
      queryFn: () => listSessions(token, accompanimentId),
    },
  ]);

  const buildSession = (session, index) => {
    const title = `Sesión ${session.number} #${session.id}`;

    const format = 'DD [de] MMMM [del] YYYY [a las] HH:mm a';
    const startDate = moment(session.start_date).format(format);
    const endDate = moment(session.end_date).format(format);
    const parsedStatus = sessionStatuses[String(session.status)];
    const infoDescription = [
      {
        children: (
          <p className={`font-bold m-0 text-gray-500 text-xs`}>
            <span className="font-body">Empieza: </span>
            {startDate}
          </p>
        ),
        icon: <CalendarIcon className="ml-0 h-4 w-4 lg:h-5 lg:w-5 text-gray-600 mr-2" />,
      },
      {
        children: (
          <p className={`font-bold m-0 text-gray-500 text-xs`}>
            <span className="font-body">Termina: </span>
            {endDate}
          </p>
        ),
        icon: <CalendarIcon className="ml-0 h-4 w-4 lg:h-5 lg:w-5 text-gray-600 mr-2" />,
      },
      {
        children: (
          <p className={`font-bold m-0 text-gray-500 text-xs`}>
            <span className="font-body">Status: </span>
            {parsedStatus.text}
          </p>
        ),
        icon: (
          <InformationCircleIcon
            className={`ml-0 h-4 w-4 lg:h-5 lg:w-5 ${parsedStatus.color} mr-2`}
          />
        ),
      },
    ];
    return (
      <ContactCard
        key={index}
        className="shadow-md rounded-md md:mr-4 mb-4 w-96"
        title={title}
        // buttonInfo={[
        //   {
        //     text: 'Ver Detalle',
        //     icon: <EyeIcon className="ml-0 h-5 w-5 text-primary mr-2" />,
        //     onClickEvent: () => navigate(`detail/${accompanimentRequest.id}`),
        //   },
        // ]}
        infoDescription={infoDescription}
        withDot={false}
      ></ContactCard>
    );
  };

  let patient = null;
  let psychologist = null;
  let nextSession = null;
  let startDateUTC = null;
  let endDateUTC = null;
  if (retrieveAccompanimentQuery.isSuccess) {
    patient = retrieveAccompanimentQuery.data.patient;
    psychologist = retrieveAccompanimentQuery.data.psychologist;
    nextSession = retrieveAccompanimentQuery.data.next_session;
    startDateUTC = moment(nextSession.start_date).utc();
    endDateUTC = moment(nextSession.end_date).utc();
  }

  const localTimezone = moment.tz.guess(true);
  const localCountryCode = moment.tz.zone(localTimezone).countries()[0];

  return (
    <BasicLayout title={`Detalle de Acompañamiento #${accompanimentId}`}>
      {retrieveAccompanimentQuery.isLoading && listSessionsQuery.isLoading ? (
        <div className="flex">
          <div className="mt-4 w-full flex justify-center items-center">
            <Loader className="h-12 w-12 text-primary" />
          </div>
        </div>
      ) : null}
      {retrieveAccompanimentQuery.isSuccess ? (
        <div className="flex flex-col md:flex-row w-100%">
          <div className="flex-1 flex justify-start mb-4 md:mb-0">
            <UserContent
              user={psychologist}
              title="Cather"
              startDateUTC={startDateUTC}
              endDateUTC={endDateUTC}
            />
          </div>

          <div className="flex-1 flex justify-start">
            <UserContent
              user={patient}
              title="Patient"
              startDateUTC={startDateUTC}
              endDateUTC={endDateUTC}
            />
          </div>

          <div className="flex-1 flex justify-start">
            <UserContent
              user={{
                full_name: 'Guest',
                country: {
                  name: '',
                  code: localCountryCode,
                },
                timezone: localTimezone,
              }}
              title="Local"
              startDateUTC={startDateUTC}
              endDateUTC={endDateUTC}
            />
          </div>
        </div>
      ) : null}
      <br />
      <div className="flex">
        {listSessionsQuery.isSuccess && listSessionsQuery.data.length ? (
          <div className="w-full">
            <Title className="mb-2 text-gray-500">Sesiones</Title>
            <div className="w-full flex flex-wrap">{listSessionsQuery.data.map(buildSession)}</div>
          </div>
        ) : null}
      </div>
    </BasicLayout>
  );
}
