import {
  CheckCircleIcon,
  CheckIcon,
  ClipboardListIcon,
  ClockIcon,
  SearchIcon,
  UserIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
// import Button from 'components/Button';
import ContactCard from 'components/ContactCard';
import Input from 'components/Input';
import Loader from 'components/Loader';
import TabContainer from 'components/TabContainer';
// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listAccompaniments } from 'services/accompaniment';
import { selectedAccompaniment } from 'state/accompaniment';
import { getToken } from 'state/auth';
import { isArrayEmpty } from 'utils/base';
// import { SPANISH_MONTHS } from 'utils/constants';
// import { PSYCHOLOGIST_DEGREES } from 'utils/psychologist';

const tabs = [
  {
    name: 'En progreso',
    icon: ClipboardListIcon,
    filters: { status: '2' },
  },
  {
    name: 'P/Pago',
    icon: ClockIcon,
    filters: { status: '3' },
  },
  {
    name: 'P/Paciente',
    icon: CheckCircleIcon,
    filters: { status: '4' },
  },
  {
    name: 'P/Cather',
    icon: XCircleIcon,
    filters: { status: '5' },
    urlHash: 'ip',
  },
  {
    name: 'C/Pago',
    icon: XCircleIcon,
    filters: { status: '6' },
  },
  {
    name: 'C/Paciente',
    icon: XCircleIcon,
    filters: { status: '7' },
  },
  {
    name: 'C/Cather',
    icon: XCircleIcon,
    filters: { status: '8' },
  },
  {
    name: 'C/Admin',
    icon: XCircleIcon,
    filters: { status: '9' },
  },
  {
    name: 'Finalizado',
    icon: CheckIcon,
    filters: { status: '10' },
  },
];

export default function AccompanimentList() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);
  const [, setAccompanimentID] = useRecoilState(selectedAccompaniment);

  // LOCAL STATES
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters] = useState(tabs[0].filters);
  const [search, setSearch] = useState('');

  // REACT QUERY
  const { data, isSuccess, isLoading, refetch } = useQuery(
    ['listAccompanimentRequests', token, filters, search],
    () => listAccompaniments(token, filters, search),
    {
      enabled: false,
    },
  );

  // ROUTER
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  useEffect(() => {
    const selectedTab = tabs[`${activeTab}`];
    setFilters(selectedTab.filters);
  }, [activeTab]);

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  useEffect(() => {
    const timeOut = setTimeout(() => refetch(), 500);
    return () => clearTimeout(timeOut);
  }, [search, refetch]);

  const searchHandler = (event) => {
    setSearch(event.target.value);
  };

  return (
    <BasicLayout title="Acompañamientos">
      <TabContainer tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

      <div>
        <Input
          id="search"
          type="text"
          onChange={searchHandler}
          register={() => null}
          registerConfig={{}}
          icon={<SearchIcon className="h-6 w-6 text-gray-400" />}
          leftIcon={true}
          placeholder="Search"
          disabled={false}
        />
      </div>

      <div>
        {isLoading ? (
          <div className="w-full flex justify-center items-center text-center font-body mt-10">
            <Loader /> Buscando...
          </div>
        ) : (
          isSuccess &&
          isArrayEmpty(data) && <div className="text-center mt-10">Ningún resultado</div>
        )}
        {isSuccess && !isArrayEmpty(data) && (
          <div className="flex flex-col md:grid grid-cols-2 auto-rows-fr gap-x-3 box-border">
            {data.map((accompaniment, index) => {
              const title = `${accompaniment.title} #${accompaniment.id}`;
              const patient = accompaniment.patient;
              const psychologist = accompaniment.psychologist;
              // const createdDate = moment(psychologist.created_at, 'DD-MM-YYYYTHH:mm:ssZ').toDate();
              const infoDescription = [
                {
                  children: (
                    <p className={`font-bold m-0 text-gray-500 text-xs text-left`}>
                      <span className="font-body">Paciente: </span>
                      {patient.first_name} {patient.last_name}
                      <br />
                      <span className="font-body">Email: </span>
                      {patient.email}
                    </p>
                  ),
                  icon: <UserIcon className="ml-0 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 mr-2" />,
                },
                {
                  children: (
                    <p className={`font-bold m-0 text-gray-500 text-xs text-left`}>
                      <span className="font-body">Cather: </span>
                      {psychologist ? `${psychologist.first_name} ${psychologist.last_name}` : '-'}
                      <br />
                      <span className="font-body">Email: </span>
                      {psychologist ? `${psychologist.email}` : '-'}
                    </p>
                  ),
                  icon: <UserIcon className="ml-0 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 mr-2" />,
                },
              ];

              // const degree = PSYCHOLOGIST_DEGREES[psychologist.degree];
              return (
                <button
                  key={index}
                  className="my-4 border rounded-lg shadow-md"
                  onClick={() => {
                    setAccompanimentID(accompaniment.id);
                    navigate(`detail`);
                  }}
                >
                  <ContactCard
                    className="text-left"
                    title={title}
                    // buttonInfo={[
                    //   {
                    //     text: 'Ver Detalle',
                    //     icon: <EyeIcon className="ml-0 h-5 w-5 text-primary mr-2" />,
                    //     onClickEvent: () => navigate(`detail/${accompanimentRequest.id}`),
                    //   },
                    // ]}
                    infoDescription={infoDescription}
                    withDot={false}
                  ></ContactCard>
                </button>
              );
            })}
          </div>
        )}
      </div>
    </BasicLayout>
  );
}
