import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const PRIVATE_PATIENT_PATH = 'private/patients';
const PATIENT_PATH = 'patients';

export const listMktPatients = async (token) => {
  const { data } = await axios.get(`${API_URL}${PATIENT_PATH}/mkt/`, {
    headers: headers(token),
  });

  return data;
};

export const listPatients = async (token, filters, search) => {
  /**
   * is_active -> 0 inactive, 1 active
   * search -> filters for pat by first_name or last_name
   */
  const { data } = await axios.get(`${API_URL}${PRIVATE_PATIENT_PATH}`, {
    headers: headers(token),
    params: {
      is_active: filters.isActive,
      search: search,
      is_suspended: filters.isSuspended,
      statuses: filters.status,
    },
  });

  return data;
};

export const retrievePatient = async (token, id) => {
  // id -> patient_id
  const { data } = await axios.get(`${API_URL}${PRIVATE_PATIENT_PATH}/${id}`, {
    headers: headers(token),
  });

  return data;
};

export const updatePatientState = async (token, id, isActive) => {
  // id -> patient_id
  const { data } = await axios.patch(
    `${API_URL}${PRIVATE_PATIENT_PATH}/${id}/`,
    {
      is_active: isActive,
    },
    {
      headers: headers(token),
    },
  );
  return data;
};

export const getPatientsSessions = async (token, id) => {
  // id -> patient_id
  const { data } = await axios.get(`${API_URL}${PATIENT_PATH}/${id}/sessions`, {
    headers: headers(token),
  });

  return data;
};
