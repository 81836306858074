import './index.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css'; // airbnb

import Accompaniment from 'pages/Accompaniment';
import AccompanimentDetail from 'pages/Accompaniment/Detail';
import AccompanimentList from 'pages/Accompaniment/List';
import AccompanimentRequest from 'pages/AccompanimentRequest';
import AccompanimentRequestList from 'pages/AccompanimentRequest/List';
import AdminAuth from 'pages/Authentication/AdminAuth';
import RequireAuth from 'pages/Authentication/RequireAuth';
import CustomQueryClientProvider from 'pages/CustomQueryClientProvider';
import Home from 'pages/Home';
import Hops from 'pages/HOPs/';
import Allhops from 'pages/HOPs/AllHops';
import NewHop from 'pages/HOPs/CreateHop';
import Landing from 'pages/Landing';
import Login from 'pages/Login';
import Patients from 'pages/Patients';
import AllPatients from 'pages/Patients/AllPatients';
import DetailPatient from 'pages/Patients/Detail';
// import Accompaniment from 'pages/Patients/Detail/Accompaniment';
import PatientCalendar from 'pages/Patients/Detail/Calendar';
import PatientProfile from 'pages/Patients/Detail/Profile';
import Payment from 'pages/Payment';
import PaymentExport from 'pages/Payment/Export';
import PaymentList from 'pages/Payment/List';
import Psychologists from 'pages/Psychologists';
import AllPsychologists from 'pages/Psychologists/AllPsychologists';
import NewPsychologist from 'pages/Psychologists/CreatePsychologist';
import DetailPsychologist from 'pages/Psychologists/Detail';
import PsychologistCalendar from 'pages/Psychologists/Detail/Calendar';
import PsychologistPatients from 'pages/Psychologists/Detail/Patients';
import PsychologistProfile from 'pages/Psychologists/Detail/Profile';
import Tickets from 'pages/Tickets';
import TicketsList from 'pages/Tickets/List';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <CustomQueryClientProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="ic"
              element={
                <RequireAuth>
                  <App />
                </RequireAuth>
              }
            >
              <Route index element={<Home />} />
              <Route path="psychologists" element={<Psychologists />}>
                <Route index element={<AllPsychologists />} />
                <Route path="new" element={<NewPsychologist />} />
                <Route path="detail/:id" element={<DetailPsychologist />}>
                  <Route index element={<PsychologistProfile />} />
                  <Route path="patients" element={<PsychologistPatients />} />
                  <Route path="calendar" element={<PsychologistCalendar />} />
                </Route>
              </Route>
              <Route path="patients" element={<Patients />}>
                <Route index element={<AllPatients />} />
                <Route path="detail" element={<DetailPatient />}>
                  <Route index element={<PatientProfile />} />
                  {/* <Route path="accompaniment" element={<Accompaniment />} /> */}
                  <Route path="calendar" element={<PatientCalendar />} />
                </Route>
              </Route>
              <Route
                path="hops"
                element={
                  <AdminAuth>
                    <Hops />
                  </AdminAuth>
                }
              >
                <Route index element={<Allhops />} />
                <Route path="new" element={<NewHop />} />
              </Route>
              {/*  Accompaniment requests */}
              <Route path="accompaniment-requests" element={<AccompanimentRequest />}>
                <Route index element={<AccompanimentRequestList />} />
              </Route>
              {/*  Accompaniments */}
              <Route path="accompaniments" element={<Accompaniment />}>
                <Route index element={<AccompanimentList />} />
                <Route path="detail" element={<AccompanimentDetail />} />
              </Route>
              {/*  Payments */}
              <Route path="payments" element={<Payment />}>
                <Route index element={<PaymentList />} />
                <Route path="export" element={<PaymentExport />} />
              </Route>
              {/*  Tickets */}
              <Route path="tickets" element={<Tickets />}>
                <Route index element={<TicketsList />} />
                <Route path="detail" element={<AccompanimentDetail />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </CustomQueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
