import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

import { uploadPhoto } from './generics';

const PSYCHOLOGIST_PRIVATE_PATH = 'private/psychologists';
const PSYCHOLOGIST_PATH = 'psychologists/';

export const listPsychologists = async (token, filters, search) => {
  /**
   * is_active -> 0 inactive, 1 active
   * search -> filters for psy by first_name or last_name
   */
  const { data } = await axios.get(`${API_URL}${PSYCHOLOGIST_PRIVATE_PATH}`, {
    headers: headers(token),
    params: {
      is_active: filters.isActive,
      search: search,
    },
  });

  return data;
};

export const getPsychologistsPatients = async (token, id) => {
  // id -> psychologist_id
  const { data } = await axios.get(`${API_URL}${PSYCHOLOGIST_PATH}${id}/patients`, {
    headers: headers(token),
  });

  return data;
};

export const retrievePsychologist = async (token, id) => {
  // id -> psychologist_id
  const { data } = await axios.get(`${API_URL}${PSYCHOLOGIST_PRIVATE_PATH}/${id}`, {
    headers: headers(token),
  });

  return data;
};

export const updatePsychologist = async (
  token,
  id,
  firstName,
  lastName,
  email,
  video,
  degree,
  reason,
  aboutMe,
  isActive,
  bankAccount,
  // address,
  // experiences,
) => {
  const { data } = await axios.patch(
    `${API_URL}${PSYCHOLOGIST_PRIVATE_PATH}/${id}/`,
    {
      first_name: firstName,
      last_name: lastName,
      email,
      video,
      degree,
      mindove_message: reason,
      biography: aboutMe,
      is_active: isActive,
      bank_account: bankAccount,
      // address: {
      //   aka: address.aka,
      //   street: address.street,
      //   ext_number: address.extNumber,
      //   int_number: address.intNumber,
      //   city: address.city,
      //   zip_code: address.zipCode,
      //   default: address.default,
      //   state: address.state,
      //   country: address.country,
      //   timezone: address.timezone,
      // },
      // experiences,
    },
    {
      headers: headers(token),
    },
  );
  return data;
};

export const updatePsychologistPhoto = async (token, catherID, file) => {
  let uploadResponse = await uploadPhoto(token, file);
  let photoURL = uploadResponse.url;
  const { data } = await axios.patch(
    `${API_URL}${PSYCHOLOGIST_PRIVATE_PATH}/${catherID}/photo/`,
    {
      photo_URL: photoURL,
    },
    {
      headers: headers(token),
    },
  );

  return data.data;
};

export const getPsychologistSessions = async (token, id) => {
  // id -> psychologist_id
  const { data } = await axios.get(`${API_URL}${PSYCHOLOGIST_PATH}${id}/sessions`, {
    headers: headers(token),
  });
  return data;
};

export const getAllPsychologists = async (token) => {
  const { data } = await axios.get(`${API_URL}${PSYCHOLOGIST_PATH}`, {
    headers: headers(token),
  });
  return data;
};
