import {
  CalendarIcon,
  DocumentDownloadIcon,
  LocationMarkerIcon,
  MailIcon,
  SearchIcon,
  UserIcon,
  UserRemoveIcon,
} from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import ContactCard from 'components/ContactCard';
import Input from 'components/Input';
import Loader from 'components/Loader';
import TabContainer from 'components/TabContainer';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listMktPatients, listPatients } from 'services/patient';
import { getToken } from 'state/auth';
import { selectedPatient as selectedPatientState } from 'state/patient';
import { isArrayEmpty } from 'utils/base';
import { SPANISH_MONTHS } from 'utils/constants';
import { STATUS_PATIENT } from 'utils/patient';

const tabs = [
  {
    name: 'Activos',
    icon: UserIcon,
    filters: { isActive: true, isSuspended: false, status: '2,3' },
  },
  {
    name: 'Inactivos',
    icon: UserRemoveIcon,
    filters: { isActive: true, isSuspended: false, status: '0,1' },
  },
  {
    name: 'Suspendidos',
    icon: UserRemoveIcon,
    filters: { isActive: false, isSuspended: true },
  },
];

export default function AllPatients() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);
  const [, setSelectedPatient] = useRecoilState(selectedPatientState);

  // LOCAL STATES
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters] = useState(tabs[0].filters);
  const [search, setSearch] = useState('');
  const [isLoadingMKTData, setIsLoadingMKTData] = useState(false);

  // REACT QUERY
  const { data, isLoading, isSuccess, refetch } = useQuery(
    ['listPatients', token, filters, search],
    () => listPatients(token, filters, search),
    {
      enabled: false,
    },
  );

  // RRD
  const navigate = useNavigate();

  useEffect(() => {
    const selectedTab = tabs[`${activeTab}`];
    setFilters(selectedTab.filters);
  }, [activeTab]);

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  useEffect(() => {
    const timeOut = setTimeout(() => refetch(), 500);
    return () => clearTimeout(timeOut);
  }, [search, refetch]);

  const searchHandler = (event) => {
    setSearch(event.target.value);
  };

  // Function to trigger the download
  const downloadJson = async () => {
    setIsLoadingMKTData(true);
    const jsonData = await listMktPatients(token);
    if (jsonData) {
      const dataStr =
        'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(jsonData));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', 'data.json');
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
      toast.success('Descarga exitosa.');
    } else {
      toast.error('Algo salio mal.');
    }
    setIsLoadingMKTData(false);
  };

  // TODO: manage errors
  return (
    <BasicLayout
      title="Pacientes"
      button={
        <Button
          disabled={isLoadingMKTData}
          className="rounded-md"
          icon={
            isLoadingMKTData ? (
              <Loader className="text-white h-6 w-6" />
            ) : (
              <DocumentDownloadIcon className="h-5 w-5 text-white mr-2" />
            )
          }
          primary
          onClick={downloadJson}
        >
          Exportar Usuarios
        </Button>
      }
    >
      <TabContainer tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>
        {/* TODO: check RHF here! */}
        <Input
          id="search"
          type="text"
          onChange={searchHandler}
          register={() => null}
          value={search}
          registerConfig={{}}
          icon={<SearchIcon className="h-6 w-6 text-gray-400" />}
          leftIcon={true}
          placeholder={search}
          disabled={false}
        />
      </div>

      <div>
        {isLoading ? (
          <div className="w-full flex justify-center items-center text-center font-body mt-10">
            <Loader /> Buscando...
          </div>
        ) : null}

        {isSuccess && isArrayEmpty(data) ? (
          <div className="text-center font-body mt-10">No se encontraron resultados</div>
        ) : null}

        {isSuccess && !isArrayEmpty(data) && (
          <div className="flex flex-col md:grid grid-cols-2 auto-rows-fr gap-x-3 box-border">
            {data.map((patient, index) => {
              const createdDate = moment(patient.created_at, 'DD-MM-YYYYTHH:mm:ssZ').toDate();
              const infoDescription = [
                {
                  children: (
                    <p className={`font-body m-0 text-gray-500 text-xs`}>
                      {patient.address?.state.name}, {patient.address?.state.country.name}
                    </p>
                  ),
                  icon: <LocationMarkerIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
                },
                {
                  children: (
                    <p className={`font-body m-0 text-gray-500 text-xs`}>
                      Paciente desde <strong>{SPANISH_MONTHS[createdDate.getMonth()]}</strong>
                    </p>
                  ),
                  icon: <CalendarIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
                },
              ];

              return (
                <div key={index} className="my-4 border rounded-lg shadow-md">
                  <ContactCard
                    title={patient.first_name + ' ' + patient.last_name}
                    buttonInfo={[
                      {
                        text: 'Detalles',
                        icon: <MailIcon className="ml-0 h-5 w-5 text-primary mr-2" />,
                        onClickEvent: () => {
                          setSelectedPatient(patient.id);
                          navigate(`detail/`);
                        },
                      },
                    ]}
                    infoDescription={infoDescription}
                    tag={STATUS_PATIENT[parseInt(patient.status)].tag}
                    tagColor={STATUS_PATIENT[parseInt(patient.status)].tagColor}
                    withDot={STATUS_PATIENT[parseInt(patient.status)].withDot}
                  >
                    <img className="rounded-full h-10 w-10" src={patient.photo_URL} alt="" />
                  </ContactCard>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </BasicLayout>
  );
}
